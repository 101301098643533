
import React from "react";
function Page404() {
	return (
		<div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", height:"50vh"}}>
		<h2 style={{textAlign: "center", width:"100%", margin:"0"}}>404 找不到頁面</h2>
	  </div>
	)
}

export default Page404;