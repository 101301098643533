import { Outlet } from "react-router-dom"
import { Helmet } from "react-helmet"
import EndSubmissionMessage from "./EndSubmissionMessage"

function AppForm2023Layout() {

	function shareFunc(sns_type) {
		if(sns_type == undefined) return

		let share_link = 'https://www.gbayouthforum.org.hk/2023/form/'

		switch(sns_type){
			case "facebook":
				window.open("https://www.facebook.com/sharer/sharer.php?u=" + share_link);
				break;

			case "whatsapp":
				window.open("https://api.whatsapp.com/send?text=" + share_link);
				break;

			case "wechat":
				window.open("https://api.qrserver.com/v1/create-qr-code/?data=" + share_link);
				break;

			case "email":
				let email_subject_body = encodeURIComponent( "\n\n" + share_link + "\n\n" )
				window.open( "mailto:?subject=立即報名: 大灣區青年發展論壇2023&body=立即報名: 大灣區青年發展論壇2023" + email_subject_body)
				break;
		}
	}

	const checkApplicationEnd = () => {
		const currentDate = new Date();
		const endDate = new Date('2023-11-01'); //after 
		//const endDate = new Date('2023-09-11'); //after 
		return currentDate > endDate;
	};
	
	return (
		<div>
			<Helmet>
				<title>大灣區青年發展論壇2023報名表格</title>
				<meta property="og:title" content="大灣區青年發展論壇2023報名表格" />
				<meta property="og:site_name" content="大灣區青年發展論壇" />
				<meta property="og:url" content="https://www.gbayouthforum.org.hk/2023/form/" />
				<meta property="og:description" content="&nbsp;" />
				<meta property="og:image" content="https://www.gbayouthforum.org.hk/images/2023/kv.jpg" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="628" />
				<link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/2023/application_form.css"} />
			</Helmet>
			<div className="container">
				<header>
					<ul className="share_buttons">
						<li className="facebook" title="Share on Facebook" onClick={() =>shareFunc('facebook')}></li>
						<li className="whatsapp" title="Share on Whatsapp" onClick={() =>shareFunc('whatsapp')}></li>
						<li className="wechat" title="Share on WeChat" onClick={() =>shareFunc('wechat')}></li>
						<li className="email" title="Share by Email" onClick={() =>shareFunc('email')}></li>
					</ul>
				</header>
				<div id="event_desc_wrapper">
					<section id="key_visual">
						<div id="key_visual_img_mobile"><img src="/images/2023/kv_mobile.jpg" border="0" /></div>
						<div id="key_visual_img"><img src="/images/2023/kv.jpg" border="0" /></div>
					</section>
					{checkApplicationEnd() ? (
						<EndSubmissionMessage />
					)
					:
					(
						<Outlet />
					)
					}
				</div>
			</div>
		</div>
	)
}

export default AppForm2023Layout
