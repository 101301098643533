function MiniSite2022Highlight() {
	return (
			<div id="MainWrapper" category="Highlight">
				<section className="LMargin">
					<div className="Container">
						<div className="SectionEventName"><img src={process.env.PUBLIC_URL + '/images/2022/event_name_section.svg'} alt="大灣區青年發展論壇2022" /></div>
						<h2>活動花絮</h2>
					</div>
				</section>
				<section>
					<div className="Container">
						<div className="HighlightWrapper">
							<div className="Video">
								<video controls playsInline poster={process.env.PUBLIC_URL + '/images/2022/video/poster.jpg'}>
									<source src="https://webhost.etnet.com.hk/videos/projects/gba/2022/forum.mp4" type="video/mp4" />
								</video>
							</div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/001.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/002.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/003.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/004.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/005.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/006.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/007.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/008.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/009.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/010.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/011.jpg'} alt="" /></div>
							<div className="AlbumCard"><img src={process.env.PUBLIC_URL + '/images/2022/highlight/012.jpg'} alt="" /></div>
						</div>
					</div>
				</section>
			</div>
	);
}

export default MiniSite2022Highlight;
