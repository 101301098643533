
import React from "react";

function EventDescription() {
	return (
		<div>
			<section id="event_desc">
				<h1>立即報名: 大灣區青年發展論壇2023 | 多元共融 開創明天</h1>
				<a className="btn backto2022" target="_blank" href="/2022/"> 2022 論壇回顧 </a>
				<p>由大灣區共同家園青年公益基金主辦，香港經濟日報集團及大灣區共同家園發展基金聯合主辦的「大灣區青年發展論壇」， 2023年主題為「多元共融 開創明天」。論壇面向青年、在校大專及高中學生，聚焦「創業跨領域」、「創科跨世代」及「文體跨國界」三大青年切身議題，讓各界持份者進行現況分享及趨勢討論，令有不同興趣和志向的青年人發掘在創業、創科、文化及體育等不同領域的發展路向。</p>
				<p>
				大灣區建設是新時代國家改革開放下的重大戰略，而「十四五」規劃中更將香港確立為八個重點領域的發展中心（「八大中心」），包括國際金融中心、國際創新科技中心、中外文化藝術交流中心等，進一步推進香港多元化的發展，造就了多方面融合的機遇。國家一向重視青年人的發展，國家主席習近平在2022年「七一」重要講話中，表示青年興，則香港興；青年發展，則香港發展；青年有未來，則香港有未來。青年可透過香港多元化的發展策略，並把握大灣區發展的獨特優勢，在更大的空間激發潛能，發揮所長，實現理想。
				</p>
				<p>
				年青人最具創新和創意，培養青年的多元發展，融合各方力量，可鞏固香港在國家發展中的優勢。年青人應珍惜及把握機遇，走出舒適圈多觀察、多看看、多交朋友及多交流，為未來更廣闊的事業舞台做好準備，開創明天。
				</p>
				<p>
				主辦機構：大灣區共同家園青年公益基金<br />
				聯合主辦：香港經濟日報集團、大灣區共同家園發展基金
				</p>
				<p>
				論壇誠邀全港青年、在校大專及高中學生一同出席。
				<br />
				參加者將獲大會贈送紀念品乙份
				</p>
			</section>
			<hr />
			<section className="agenda">
			日期：2023年11月2日（星期四）
					<br />
					時間：下午2:30 - 5:35
					<br />
					地點：香港會議展覽中心演講廳1
					<br />
					語言：廣東話 / 普通話
			</section>
			<section className="agenda">
				開幕儀式：
				<br />
				<br />
				主禮嘉賓：
				<br />
				麥美娟女士，SBS，JP
				<br />
				香港特別行政區政府民政及青年事務局局長
				<br />
				<br />
				黃永光先生，SBS，JP
				<br />
				大灣區共同家園青年公益基金主席、信和集團副主席
				<br/>
				<br/>
				主題嘉賓講者：
				<br />
				李帆風先生
				<br />
				中國移動香港有限公司董事兼行政總裁

			</section>
			<section className="agenda">
				專題演講及討論：
				<br />
				專題一：「創業跨領域」
				<br />
				<br />
				嘉賓講者：
				<br />
				黃克強先生
				<br />
				香港科技園公司行政總裁
				<br />
				<br />
				王志豪先生
				<br />
				Klook營運長COO暨聯合創辦人
				<br/>
				<br />
				關俊文先生
				<br />
				GoGoX聯合創辦人
				<br/>
				<br/>
				譚嗣籇先生
				<br />
				綠芝園Farm66公司創辦人及行政總裁
				<br/>
				<br/>
				主持人：
				<br />
				粘清澤先生
				<br />
				香港科技園公司創科培育及企業加速計劃總監 
				<br />
				<br />
				專題二：「創科跨世代」
				<br />
				<br />
				主持人、嘉賓講者：
				<br />
				邱達根先生
				<br />
				香港特別行政區立法會議員（科技創新界）、香港資訊科技聯會會長
				<br />
				<br />
				嘉賓講者：
				<br />
				李煥明博士
				<br />
				其士（網絡科技）有限公司總經理
				<br />
				<br />
				莫樹錦教授，BBS
				<br />
				香港中文大學醫學院腫瘤學系系主任
				<br />
				<br />
				潘智豪先生
				<br />
				博勝集團聯合創辦人暨行政總裁、2022年十大傑青
				<br />
				<br />
				專題三：「文體跨國界」
				<br />
				<br />
				主持人、嘉賓講者：
				<br />
				毛俊輝教授，BBS
				<br/>
				香港話劇團桂冠導演
				<br />
				<br />
				嘉賓講者：
				<br />
				王祖藍先生
				<br />
				手工藝創作有限公司董事
				<br />
				<br />
				林屴汧先生
				<br />
				本地樂團及合唱團藝術總監及首席指揮
				<br />
				<br />
				張小倫先生
				<br/>
				香港劍擊運動員
				<br />
				<br />
				支持機構 （按機構名稱筆畫序）:
				<br/>
				<br/>
				鳴謝
				<br/>
				<br/>
				中央人民政府駐香港特別行政區聯絡辦公室青年工作部
				<br/>
				<br/>
				香港特別行政區政府部門
				<br/>
				文化體育及旅遊局
				<br/>
				民政及青年事務局
				<br/>
				政制及內地事務局
				<br />
				財經事務及庫務局
				<br />
				創新科技及工業局
				<br />
				<br />
				教育機構
				<br/>
				香港大學
				<br/>
				香港中文大學
				<br/>
				香港恒生大學
				<br/>
				香港城市大學
				<br/>
				香港都會大學
				<br/>
				香港珠海學院
				<br/>
				香港浸會大學
				<br/>
				香港理工大學
				<br/>
				香港教育大學
				<br/>
				香港專業進修學校
				<br/>
				香港演藝學院
				<br/>
				香港樹仁大學
				<br/>
				嶺南大學
				<br/>
				職業訓練局
				<br/>
				<br/>
				其他機構
				<br/>
				MWYO 青年辦公室
				<br/>
				百仁基金
				<br/>
				青年專業聯盟
				<br/>
				明匯智庫
				<br/>
				香港互聯網專業協會
				<br/>
				香港各界青少年活動委員會
				<br/>
				香港青年交流促進聯會
				<br/>
				香港青年協會
				<br/>
				香港青年創業家總商會
				<br/>
				香港青年聯會
				<br/>
				香港科技創新促進組
				<br/>
				香港科技園公司
				<br/>
				香港菁英會
				<br/>
				香港華菁會
				<br/>
				香港藝術發展局
				<br/>
				國際青年商會香港總會
				<br/>
				粵港澳大灣區青年總會
				<br/>
				團結香港基金
				<br/>
				香港創科發展協會
				<br/>
				數碼港
				<br/>
				<br/>
				媒體伙伴
				<br/>
				中國日報香港版
				<br/>
				文匯報
				<br/>
				𤆥見名家
				<br/>
			</section>
		</div>
	)
}

export default EventDescription;