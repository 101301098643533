import React from "react";
import Contact from "./Contact";

function Disclaimer() {
	return (
	<section id="disclaimer">
		<h5 className="section_title">推廣活動條款及細則：</h5>
		<h5>活動須知及條款</h5>
		<ol>
			<li>本活動並不接受現場登記。</li>
			<li>閣下在此報名表格及填寫的資料，依閣下所知均屬完整真確。</li>
			<li>閣下在填寫報名表前，已細閱論壇之相關資料，如有錯漏之處而令閣下蒙受損失，主辦機構大灣區共同家園青年公益基金，聯合主辦經濟日報集團及大灣區共同家園發展基金一概不須負責。</li>
			<li>閣下如成功登記，須按時出席。</li>
			<li>閣下明白報名表上之資料將被紀錄並同意該等資料可供主辦機構大灣區共同家園青年公益基金，聯合主辦經濟日報集團及大灣區共同家園發展基金作日後市場推廣之用途。</li>
			<li>任何因電腦、網路等技術問題而引致閣下所遞交的資料有遲延、遺失、錯誤、無法辨識等情況，主辦機構大灣區共同家園青年公益基金，聯合主辦經濟日報集團及大灣區共同家園發展基金毋須負上任何責任。所有與活動有關之日期及時間（包括但不限於參加活動之日期及時間、閣下回覆資料之日期及時間）均以主辦機構大灣區共同家園青年公益基金，聯合主辦經濟日報集團及大灣區共同家園發展基金系統報告為準及受制於所有主辦機構的最終決定。所有主辦機構毋須就遺失、延遲或誤投，負上任何責任。</li>
			<li>參加者必須衣著整齊，衣冠不整者或穿著不適當服裝者可被拒絕進場。</li>
			<li>如發現或懷疑有人破壞活動過程，或有違反活動規則的行為，所有主辦機構有權取消任何人士的參加資格，而毋須另行通知。</li>
			<li>未經主辦機構許可，禁止在場內拍攝、錄音及錄影；所有攝錄機及錄音設備均不得攜帶入場。</li>
			<li>若閣下成功登記參與本活動，即表示同意主辦機構大灣區共同家園青年公益基金，聯合主辦經濟日報集團及大灣區共同家園發展基金為參加者於活動中被拍攝的照片及/或影片的擁有者，並擁有相關法定權利（包括）版權及使用權，並可將其作任何宣傳或推廣用途。</li>
			<li>香港天文台若在活動當日上午6時前發出8號或更高的烈風或暴風信號或黑色暴雨警告，是日活動將延期舉行。若香港天文台在活動當日上午6時前除下8號或更高的烈風或暴風信號或黑色暴雨警告，是日活動將如常進行。</li>
			<li>主辦機構大灣區共同家園青年公益基金，聯合主辦經濟日報集團及大灣區共同家園發展基金將保留最終決定權，包括隨時暫停、更改或終止活動及其條款及細則，而毋須作另行通知。</li>
		</ol>
		<br />
		<h5>私隱政策聲明</h5>
		<ul>所有主辦機構會全力執行和遵守《個人資料（私隱）條例》的有關條文，並承諾：
			<li>實行電腦、實地及程序上的保護措施，以保障所有主辦機構所收集個人資料的安全性及機密性將所收集的個人資料局限在能夠提供閣下要求的服務所需的最低範圍，只准許經授權的有關僱員及其他有關執行機構僱員接觸個人資料。</li>
			<li>不會向其他第三方披露閣下的個人資料，但如屬經閣下同意、所有主辦機構在法律上被規定或所有主辦機構之前已通知閣下的披露則除外。</li>
		</ul>
		<br />
		<h5>收集或持有的個人資料類別</h5>
		<ul>所有主辦機構所收集或持有的個人資料分別載於以下記錄：
			<li>登記：包括但不限於登記人之姓名、電話號碼、電郵地址、所屬學校及組職。</li>
			<li>查詢/投訴：包括但不限於就所有主辦機構的工作和所提供之服務所提出的查詢和投訴，以及所有主辦機構跟進該查詢和投訴時，所有主辦機構向有關人士所收集的個人資料，例如：姓名、電話號碼和電郵地址。</li>
		</ul>
		<br />
		<h5>收集和保存個人資料的目的及用途</h5>
		<ul>所有主辦機構所收集和保存的個人資料與以下範疇有關：
			<li>登記：收集和保存的資料，以便我們有效管理出席是次活動及通知閣下有關報名的狀況。在登記表格內提交不完備或不正確的資料，會影響所有主辦機構考慮和審理有關登記表格，並可能引致該登記遭延遲或拒絕受理。</li>
			<li>查詢／投訴：收集和保存資料的目的是就有關查詢／投訴採取跟進工作。</li>
			<li>提供予所有主辦機構的資料，包括任何於《個人資料（私隱）條例》訂明的個人資料，將僅被用作有關是次論壇的用途。</li>
		</ul>
		<br />
		<h5>披露</h5>
		<ul>所有主辦機構將會對所持有的個人資料保密，但可能會將閣下的個人資料提供予：
			<li>與所有主辦機構業務運作或服務有關的工作人員、代理、承辦商或服務提供者</li>
			<li>對所有主辦機構有保密責任的人士</li>
			<li>根據香港境內或境外適用法律，所有主辦機構須向其作出披露的人士</li>
		</ul>
		<br />
		<h5>保安</h5>
		<ul>
			<li>以電子形式儲存的個人資料會受密碼保護。在所有主辦機構所保障的網頁範圍內會使用加密技術。所有主辦機構的電腦系統和伺服器會存置在限制區內。所有主辦機構只准獲授權的工作人員、代理、承辦商或服務提供者接觸個人資料。</li>
			<li>所有主辦機構所收集及儲存的個人資料會於2023年11月30日或以前刪除。</li>
		</ul>
		<br />
		<h5>閣下如欲查詢或修改個人資料，請電郵至<Contact />。</h5>
		<br />
	</section>
	)
}

export default Disclaimer;
