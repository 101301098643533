import { Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../../assets/css/2022/style.css';

function MiniSite2022Layout() {
	const location = useLocation()
	return (
		<div className={location.pathname!='/2022/'?'MainWrapperBg':''}>
			<Helmet>
                <title>大灣區青年發展論壇2022</title>
				<script src={process.env.PUBLIC_URL + "/js/2022/controller.js"} type="text/javascript" />
            </Helmet>
			<div id="MenuIcon">
				<div>
					<div className="Default"></div>
					<div className="Default"></div>
					<div className="Default"></div>
					<div className="Extend"></div>
					<div className="Extend"></div>
				</div>
			</div>
			<nav>
				<div id="MenuWrapper" className="Container">
					<a href="/2022/" className="Home">主頁</a>
					<a href="/2022/panel" className="Panel">專題演講講者</a>
					<a href="/2022/highlight" className="Highlight">活動花絮</a>
				</div>
			</nav>
			<Outlet/>
			<footer>
				<section style={{marginBottom:"0"}} >
					<div className="Container">
						<div className="FooterWrapper">
							<h6>主辦機構</h6>
							<div className="Organiser">
								<a><img src="/images/2022/organiser.png" alt="大灣區共同家園青年公益基金"/></a>
							</div>
							<h6>聯合主辦</h6>
							<div>
								<a><img src="/images/2022/coorganiser_1.png" alt="經濟日報集團"/></a>
							</div>
							<div>
								<a><img src="/images/2022/coorganiser_2.png" alt="大灣區共同家園發展基金"/></a>
							</div>
							<h6 style={{marginTop:"70px"}}>© 2022 大灣區共同家園青年公益基金</h6>
						</div>
					</div>
				</section>
			</footer>
		</div>
	);
}

export default MiniSite2022Layout;
