import { useState } from "react";
import { Route, Routes, Navigate, Link } from "react-router-dom";

//common

import Page404 from "./pages/Page404";
import PageRedirect from "./pages/PageRedirect";

//2022
import MiniSite2022Layout from "./pages/2022/Layout";
import MiniSite2022Home from "./pages/2022/Home";
import MiniSite2022Panel from "./pages/2022/Panel";
import MiniSite2022Highlight from "./pages/2022/Highlight";

//2023
//import MiniSite2023 from "./pages/2023/Home";
import AppForm2023Layout from "./pages/2023/ApplicationForm/Layout";
import AppForm2023 from "./pages/2023/ApplicationForm/Form";
import AppForm2023AfterSubmitFormMessage from "./pages/2023/ApplicationForm/AfterSubmitFormMessage";
import AppForm2023Confirmation from "./pages/2023/ApplicationForm/Confirmation";

function App() {
	const [submitstatus, setSubmitstatus] = useState(false);
	
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Navigate to="/2023/form" replace />} />
				<Route path="/redirect" element={<PageRedirect />} />
				<Route path="*" element={<Page404 />} />
				
				<Route path="/2022/" element={<MiniSite2022Layout />}>
					<Route index element={<MiniSite2022Home />} />
					<Route path="panel" element={<MiniSite2022Panel />} />
					<Route path="highlight" element={<MiniSite2022Highlight />} />
				</Route>

				<Route path="/2023/" element={<Navigate to="/2023/form" replace />} />
				{<Route path="/2023/form/" element={<AppForm2023Layout />}>
					<Route index element={<AppForm2023 />} />
					<Route path="submit_success" element={<AppForm2023AfterSubmitFormMessage />} />
				</Route>}
				{<Route path="/2023/form/confirmation" element={<AppForm2023Confirmation />}>
					<Route index element={<AppForm2023 />} />
					<Route path="submit_success" element={<AppForm2023AfterSubmitFormMessage />} />
				</Route>}
			</Routes>
		</div>
	);
}

export default App;
