import React, { useState, useEffect} from "react";
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import Disclaimer  from './Disclaimer';
import EventDescription from "./EventDescription"
import { useNavigate } from 'react-router-dom';

function AppForm2023() {
	let [fullname, setFullname] = useState("");
	const [fullnameError, setFullnameError] = useState("");
	let [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneError, setPhoneError] = useState("");
	const [institution, setInstitution] = useState("");
	const [optin, setOptin] = useState("n");
	const [optinError, setOptinError] = useState("");
	const institutionError = useState("");
	const [captchaerror, setCaptchError] = useState("");
	//const [result, setResult] = useState("");
	const [error, setError] = useState("");
	const [errorArr, setErrorArr] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		loadCaptchaEnginge(6);
	}, [loadCaptchaEnginge]);

	function reloadCap() {
		loadCaptchaEnginge(6);
		return
	}

	//Validation
	function validateForm() {
		let countDigit = 0
		let countSpecialCharacters = 0
		let user_captcha = document.getElementById('user_captcha_input').value
		let phone_number_limit = 0

		if (fullname.length == 0) {
			setFullnameError("請填寫正確姓名");
			errorArr.push('fullname')
		} else {
			setFullnameError("");
		}

		if (email.length == 0) {
			setEmailError("請填寫正確電郵");
			errorArr.push('email')
		} else {
			setEmailError("");
		}

		for (let i = 0; i < phoneNumber.length; i++) {
			const specialChars = [
				'!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '+', '=', '[', '{', ']', '}', ':', ';', '<', '>',]

			if (specialChars.includes(phoneNumber[i])) {
				countSpecialCharacters++
			} else if (!isNaN(phoneNumber[i] * 1)) {
				countDigit++
			}
		}

		const area_code = document.getElementById('area_code').value;

		phone_number_limit = (area_code == '+852' || area_code == '+853') ? 8 : 11;

		if (phoneNumber.length == 0) {
			setPhoneError("請填寫正確電話號碼");
			errorArr.push('phone')
		} else if (countSpecialCharacters > 0) {
			setPhoneError("請填寫正確電話號碼");
			errorArr.push('phone')
		} else if (countDigit < phone_number_limit) {
			setPhoneError("請填寫正確電話號碼 (" + phone_number_limit + "位數字)");
			errorArr.push('phone')
		} else {
			setPhoneError("");
		}

		if(optin == 'n'){
			setOptinError("請先閱讀並確認條款及細則");
			errorArr.push('optin')
		}else {
			setOptinError("");
		}

		if (validateCaptcha(user_captcha) === true) {
			document.getElementById('user_captcha_input').value = "";
			setCaptchError("")
		}
		else {
			document.getElementById('user_captcha_input').value = "";
			setCaptchError("請輸入正確的驗證碼")
			errorArr.push('user_captcha_input')
		}

		return errorArr.length;
	}

	const areaCodeOnChange = (event) => {
		setPhoneNumber('')
		if (event.target.value == '+852' || event.target.value == '+853') {
			document.getElementById('phone').maxLength = '8'
		} else {
			document.getElementById('phone').maxLength = '11'
		}
	};

	const setOptinOnChange = (event) => {
		if(event.target.checked){
			setOptin('y')
		}else{
			setOptin('n')
		}
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		let response_data
		fullname.trim()
		fullname = fullname.toLowerCase()
		phoneNumber.trim()
		email.trim()
		email = email.toLowerCase()
		institution.trim()
		setError('')

		const area_code = document.getElementById('area_code').value;
		const phone = area_code + phoneNumber

		// validate fields
		var error_num = validateForm();

		if (error_num > 0) {
			document.getElementById(errorArr[0]).focus();
			setErrorArr([])
			//console.log(errorArr)
			loadCaptchaEnginge(6);
			return
		}else{
			try {
				// create user query
				const mutationQuery = `
					mutation createUser($input: CreateUserInput!) {
						createUser(input: $input) {
							fullname
						}
					}
				`;
				//application form variables
				const queryVariables = {
					input: {
						fullname,
						email,
						phone,
						institution,
						optin
					},
				};
				// invoke AppSync API to create user
				const response = await fetch(process.env.REACT_APP_APIURL, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.REACT_APP_APIKEY,
					},
					body: JSON.stringify({
						query: mutationQuery,
						variables: queryVariables,
					}),
				});

				response_data = await response.json();

			} catch (err) {
				console.error("Error creating user:", err);
				//setError("Error creating user");
			} finally{
				//success
				const data = response_data;
				//console.log("data:", data);
				if (data.data.createUser !== null && data.data.createUser.fullname) {
					// clear form
					setError("");
					setFullname("");
					setEmail("");
					setPhoneNumber("");
					setInstitution("");
					setOptin("n");
					navigate('/2023/form/submit_success', { replace: false });
				} else if (data.errors && data.errors[0]["errorType"] && data.errors[0]["errorType"] == "DuplicateUserError") {
					setError("重覆登記錯誤: '電郵' 及 '登記人之姓名' 已被使用");
					loadCaptchaEnginge(6);
				}
			}

		}
	};

	return (

		<div id="before_submit">
			<EventDescription />
			<hr />	
			<form onSubmit={handleSubmit}>
				<section id="user_detail">
				<p className="section_title">所有參加者須預先登記，成功報名者將會在論壇之前收到電郵通知。名額有限，先到先得。請填寫以下表格立即報名！</p>
				<div className="form_row">

					<label htmlFor="fullname"><span className="must_fill">&nbsp;*&nbsp;</span>登記人之姓名：</label>
					<input type="text" id="fullname" className="user_input" value={fullname}
						onChange={(event) => {
							let value = event.target.value
							value = value.replace(/[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFFA-Za-z ]/ig, '')
							setFullname(value)
						}}
					/>
					{fullnameError && <div className="error_alert">{fullnameError}</div>}
				</div>
				<div className="form_row">

						<label htmlFor="email"><span className="must_fill">&nbsp;*&nbsp;</span>登記人之聯絡電郵：</label>
						<input
							type="email"
							id="email"
							className="user_input"
							value={email}
							onChange={(event) => {
								setEmail(event.target.value)
							}}
						/>
						{emailError && <div className="error_alert">{emailError}</div>}
				</div>
				<div className="form_row">
					<label htmlFor="phone"><span className="must_fill">&nbsp;*&nbsp;</span>登記人之電話：</label>
					<div className="phone_input_container">
						<select name="area_code" id="area_code" onChange={areaCodeOnChange}>
							<option value="+852">+852</option>
							<option value="+853">+853</option>
							<option value="+86">+86</option>
						</select>
						<input type="tel" id="phone" className="phone_input" maxLength="8" value={phoneNumber}
							onChange={(event) => {
								let value = event.target.value
								value = value.replace(/[^+0-9]/ig, '')
								setPhoneNumber(value)
							}}
						/>
					</div>
					{phoneError && <div className="error_alert">{phoneError}</div>}
				</div>
				<div className="form_row">
					<label htmlFor="institution">就讀學校 / 任職機構：</label>
					<input
						type="text"
						id="institution"
						className="user_input"
						value={institution}
						onChange={(event) => {
							let value = event.target.value
							value = value.replace(/[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9A-Za-z @-]+/g, '')
							setInstitution(value)
						}}
					/>
					{institutionError && <div className="error_alert">{institutionError}</div>}
				</div>
				<div className="error_alert">* 為必需填寫</div>
				</section>
				<hr />
				<Disclaimer />
				<section id="after_disclaimer">
				<div className="form_row">
					<div className="optin">
					<input type="checkbox" name="optin" id="optin" value={optin} onChange={setOptinOnChange} />
					<label htmlFor="optin">本人已閱讀並明白及同意以上條款及細則</label>
					</div>
					{optinError && <div className="error_alert">{optinError}</div>}
				</div>
				</section>
				<hr />
				<section>
					<div className="form_row" id="captchaCanvas">
						<label htmlFor="user_captcha_input">驗證碼:</label>
						<input id="user_captcha_input" name="user_captcha_input" type="text"></input>
						<div id="captcha_content">
							<LoadCanvasTemplateNoReload />
							<a id="captcha_reload_btn" type="button" onClick={reloadCap}>使用另一個圖像</a>
						</div>
						{captchaerror && <div className="error_alert captcha_error">{captchaerror}</div>}
					</div>
					<div className="form_row">
					{error && <div className="error_alert">{error}</div>}
					</div>
					<button id="form_submit_btn" className="btn" type="submit">遞交</button>
				</section>
			</form>
			</div>
	);
}

export default AppForm2023;
