import { Helmet } from "react-helmet"

function Confirmation() {
	return (
		<div>
			<Helmet>
				<title>大灣區青年發展論壇2023報名表格</title>
				<meta property="og:title" content="大灣區青年發展論壇2023報名表格" />
				<meta property="og:site_name" content="大灣區青年發展論壇" />
				<meta property="og:url" content="https://www.gbayouthforum.org.hk/2023/form/" />
				<meta property="og:description" content="&nbsp;" />
				<meta property="og:image" content="https://www.gbayouthforum.org.hk/images/2023/kv.jpg" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="628" />
				<link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/2023/application_form.css"} />
			</Helmet>
			<div className="container">
				<div id="event_desc_wrapper">
					<section id="key_visual">
						<div id="key_visual_img_mobile"><img src="/images/2023/confirmation.jpg" border="0" /></div>
						<div id="key_visual_img"><img src="/images/2023/confirmation.jpg" border="0" /></div>
					</section>
				</div>
			</div>
		</div>
	)
}

export default Confirmation;