function MiniSite2022Home() {
	return (
		<div id="MainWrapper" category="Home">
			<div id="OverlayWrapper">
				<div id="OverlayContainer">
					<a id="PopupW" href="https://gbayouthforum.org.hk/2023/form" target="_blank"><img src="/images/2022/popup_w.jpg" alt="大灣區青年發展論壇2023"/></a>
					<a id="PopupH" href="https://gbayouthforum.org.hk/2023/form" target="_blank"><img src="/images/2022/popup_h.jpg" alt="大灣區青年發展論壇2023"/></a>
					<div id="OverlayClose"><div>×</div></div>
				</div>    
			</div>
			<section id="HomeKVSection" className="Full noTopMargin">
				<div id="HomeKVWrapper" className="Container">
					<div> <img src="/images/2022/event_name.svg" alt="大灣區青年發展論壇2022" /></div>
					<div><img src="/images/2022/kv.jpg" width="2630" height="2000" alt="" /></div>
				</div>
			</section>
			<section>
				<div className="Container">
					<article>
						<p>由大灣區共同家園青年公益基金主辦，經濟日報集團、大灣區共同家園發展基金聯合主辦 ── 大灣區青年發展論壇2022，主題為「滙聚人才 敢創不同」。論壇已於10月24日假香港港麗酒店成功舉行，當日香港各界青年齊聚一堂，關注青年的發展機遇，聚焦「科創新領域」、「金融新時代」及「文體新視角」三大青年切身議題進行了現況分享及趨勢討論，鼓勵他們以更大的力量與勇氣，追尋夢想，走出自己的舒適區，定下目標迎來個人發展的好年代。</p>
						<p>是次論壇提供了一個互動平台，並邀請了不同領域的持份者，分享香港在大灣區擔當的重要角色，集思廣益，如何能結合各方力量，擴闊香港青年的視野，繼續提升大灣區的發展空間，讓香港能凝聚更多各方青年才俊，找到自己的目標與理想，並推動社會持續進步。</p>
						<p>年輕人要「站得高，望得遠」── 粵港澳大灣區為香港年青人的事業發展提供了更有爆發力的歷史性機遇，年輕一代需要多元發展，而粵港澳大灣區建設正好為他們開闢一條發展的新跑道。隨著大灣區內地各城市對港政策的放寬和完善，加上城際交通的發達，香港青年人如果能夠好好發掘和善用大灣區各城市的發展資源，相信他們將成為新一代的香港企業家或創業者，踏上更大的舞台，成就更大的夢想。</p>
					</article>
				</div>
			</section>
			<section id="HomePanel" className="Full">
				<div className="Container">
					<div className="HomePanelWrapper">
						<h1>專題演講講者</h1>
						<div>
							<h3>科創新領域</h3>
							<h6>科創人才擁抱無限機遇</h6>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_1_1.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>主持人、嘉賓講者</p>
									<h5>冼漢廸先生，MH</h5>
									<p>中手游科技集團有限公司聯合創始人、執行董事及副董事長</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_1_2.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>李鍵邦先生</h5>
									<p>鈦研科技有限公司聯合創辦人兼董事</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_1_3.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>陳易希先生，BBS</h5>
									<p>Bull.B Technology Limited 創辦人</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_1_4.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>楊全盛先生，JP</h5>
									<p>香港電競總會創會會長</p>
								</div>
							</div>
						</div>
						<div>
							<h3>金融新時代</h3>
							<h6>數碼科技創新金融服務</h6>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_2_1.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>主持人、嘉賓講者</p>
									<h5>周駱美琪女士</h5>
									<p>阿里巴巴香港創業者基金執行董事</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_2_2.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>李家達先生</h5>
									<p>WeLab Bank行政總裁兼執行董事</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_2_3.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>黃永東先生</h5>
									<p>Qupital聯合創始人兼首席執行官</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_2_4.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>顏耀輝先生</h5>
									<p>Bowtie聯合創辦人/聯合行政總裁/ 董事</p>
								</div>
							</div>
						</div>
						<div>
							<h3>文體新視角</h3>
							<h6>推動文體產業化的多元及融合發展</h6>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_3_1.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>主持人、嘉賓講者</p>
									<h5>霍啟剛先生，JP</h5>
									<p>香港立法會議員（功能界別 - 體育、演藝、文化及出版界）</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_3_2.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>胡恩威先生</h5>
									<p>進念‧二十面體藝術暨行政總監、「香港一帶一路城市文化交流會議」召集人</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_3_3.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>姜偉靖先生</h5>
									<p>微辣文化有限公司執行董事</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_3_4.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>麥雅端女士</h5>
									<p>Chocolate Rain 創辦人及創作總監</p>
								</div>
							</div>
							<div>
								<div className="Thumbnail">
									<div><img src="/images/2022/speaker_3_5.jpg" alt="" /></div>
								</div>
								<div className="Bio">
									<p>嘉賓講者</p>
									<h5>許煒森女士</h5>
									<p>前港隊三項鐵人精英運動員、兩屆奧運主持及評述員</p>
								</div>
							</div>
						</div>
						<div className="ButtonWrapper"> <a href="/2022/panel">
							<h4>了解更多</h4>
						</a> </div>
					</div>
				</div>
			</section>
			<section>
				<div className="Container">
					<h1>活動花絮</h1>
					<div className="HomeHighlightWrapper">
						<div className="Video">
							<video controls playsInline poster="/images/2022/video/poster.jpg"><source src="https://webhost.etnet.com.hk/videos/projects/gba/2022/forum.mp4" type="video/mp4" /></video>
						</div>
						<div><img src="/images/2022/highlight/001.jpg" alt="" /></div>
						<div><img src="/images/2022/highlight/002.jpg" alt="" /></div>
						<div><img src="/images/2022/highlight/003.jpg" alt="" /></div>
					</div>
					<div className="ButtonWrapper"> <a href="/2022/highlight">
						<h4>更多圖片</h4>
					</a> </div>
				</div>
			</section>
			<section className="Border">
				<div className="Container">
					<article>
						<h1 className="Margin">主辦機構</h1>
						<figure className="Logo"> <img src="/images/2022/organiser.png" alt="大灣區共同家園青年公益基金" /> </figure>
						<p>「大灣區共同家園青年公益基金」（以下稱：大灣區青年基金）於2019年9月正式成立，是以服務香港青年為主的公共性質慈善機構。大灣區青年基金的標誌為11隻彩色蝴蝶圍繞在一起飛翔，象徵著大灣區9+2地區（9個城市，2個特別行政區）的共同發展，並一起為青年於大灣區打造一個包容性強、開放度高和更好實現夢想的社會環境。</p>
						<p>大灣區青年基金匯聚了各界青年領袖，以「助青年 創明天」為使命，以教育及培訓為主導方向，集合及善用粵港澳大灣區的資源，開展與學業、就業及創業等方面的服務，為青年搭台、搭梯及搭橋，提升自身價值，積極面對挑戰，把握大灣區發展機遇，促進粵港澳大灣區建設。</p>
						<p>成立三年來，大灣區青年基金主辦及資助了逾80個項目，服務的香港青年超過55,000人次，為香港青年於大灣區的發展，提供全方位支援。2021年，大灣區青年基金推出兩大重點項目 ── 「YO PLACE 築夢空間」及「大灣區青年卡」，與香港各界青年建立起更緊密的聯繫。YO PLACE 築夢空間是一個集線上及線下的青年平台，以會員制運作，為香港青年提供粵港澳大灣區內升學、就業及創業的資訊及服務。而大灣區青年卡，則是大灣區青年基金與中國銀行（香港）、廣東省、香港及澳門青聯攜手推出的綜合青年服務卡，為在大灣區城市學習、工作及生活的港人提供全方位的生活支援及便利服務。</p>
						<p>此外，大灣區青年基金亦透過「築願計劃」，資助其他青年機構舉辦多元化的項目，如「沖」出夢想 ── 太平洋咖啡 x 港專＠大灣區創業培訓計劃、動畫創未來 ── 香港青年電腦動畫師培訓計劃、Stage Your Dream 公關及項目策劃人才培訓計劃、擊樂藝行築前程 ── 藝術行政培訓策展實踐計劃等。</p>
						<p>如欲了解更多大灣區青年基金及YO PLACE資訊，請瀏覽：<a href="https://www.gbayouth.org.hk" target="_blank" rel="external">www.gbayouth.org.hk</a>及<a href="https://www.yoplace.org.hk" target="_blank" rel="external">www.yoplace.org.hk</a>。</p>
						<h1 className="Margin">聯合主辦</h1>
						<figure className="Logo"> <img src="/images/2022/coorganiser_1.png" alt="經濟日報集團" /> </figure>
						<p>香港經濟日報集團有限公司（「經濟日報集團」╱「集團」）是一家多元化多媒體公司，其核心業務為出版香港經濟日報。該報章於1988年創立，是香港具領導地位的財經報章。於2011年，本集團推出大眾化免費派發的報章晴報。除經營報章雜誌及其數碼業務外，本集團也經營招聘廣告及優質生活平台業務，以及財經通訊社、資訊及軟件業務 ── 「經濟通」是一家具領導地位的財經通訊社，為香港及大中華區專業市場提供資訊及服務。經濟日報集團於2005年8月3日在香港聯合交易所有限公司主板掛牌上市（股份代號：00423）。</p>
						<figure className="Logo"> <img src="/images/2022/coorganiser_2.png" alt="大灣區共同家園發展基金" /> </figure>
						<p>大灣區共同家園發展基金成立於2018年底，由國際大型產業機構、金融機構以及新經濟企業聯合發起設立，總部位於香港。大灣區共同家園發展基金旨在把握粵港澳大灣區發展、國際科創中心建設等歷史機遇，聚焦科技創新、產業升級、美好生活、智慧城市等相關領域，覆蓋風險投資、私募股權投資、上市公司投資、並購投資等項目全生命週期，為優秀的創業者和企業提供資金支持、對接產業和金融資源，為股東和投資者實現長期回報，為經濟和社會發展貢獻積極力量。詳情請參閱大灣區共同家園發展基金官網：<a href="http://www.gbahomeland.com" target="_blank" rel="external">www.gbahomeland.com</a>。</p>
					</article>
				</div>
			</section>
			<section>
				<div className="Container">
					<article>
						<h1>鳴謝</h1>
						<ul>
							<li>中央人民政府駐香港特別行政區聯絡辦公室青年工作部</li>
						</ul>
						<h1>香港特別行政區政府部門</h1>
						<ul>
							<li>文化體育及旅遊局</li>
							<li>民政及青年事務局</li>
							<li>財經事務及庫務局</li>
							<li>粵港澳大灣區發展辦公室</li>
						</ul>
						<h1>教育機構</h1>
						<ul>
							<li>香港大學</li>
							<li>香港中文大學</li>
							<li>香港恒生大學</li>
							<li>香港都會大學</li>
							<li>香港理工大學</li>
							<li>香港樹仁大學</li>
							<li>珠海學院</li>
						</ul>
						<h1>其他機構</h1>
						<ul>
							<li>百仁基金</li>
							<li>青年專業聯盟</li>
							<li>香港互聯網專業協會</li>
							<li>香港各界青少年活動委員會</li>
							<li>香港青年交流促進聯會</li>
							<li>香港青年協會</li>
							<li>香港青年創業家總商會</li>
							<li>香港青年聯會</li>
							<li>香港科技創新促進組</li>
							<li>香港創科發展協會</li>
							<li>香港菁英會</li>
							<li>香港華菁會</li>
							<li>香港輔導教師協會</li>
							<li>粵港澳大灣區青年總會</li>
							<li>團結香港基金</li>
							<li>數碼港</li>
						</ul>
						<h1>媒體伙伴</h1>
						<ul>
							<li>中國日報香港版</li>
							<li>文匯報</li>
						</ul>
					</article>
				</div>
			</section>
		</div>
	);
}

export default MiniSite2022Home;
