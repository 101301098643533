function MiniSite2022Panel() {
	return (
		<div id="MainWrapper" category="Panel">
			<section className="LMargin">
				<div className="Container">
					<div className="SectionEventName"><img src={process.env.PUBLIC_URL + '/images/2022/event_name_section.svg'} alt="大灣區青年發展論壇2022" /></div>
					<h2>專題演講講者</h2>
				</div>
			</section>
			<section>
				<div className="Container">
					<div className="PanelWrapper">
						<h1>科創新領域</h1>
						<h6>科創人才擁抱無限機遇</h6>

						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_1_1.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>主持人、嘉賓講者</p>
								<h5>冼漢廸先生，MH</h5>
								<p>中手游科技集團有限公司聯合創始人、執行董事及副董事長</p>
								<p>冼漢廸先生（Hendrick）是土生土長的香港人，於史丹福大學修畢工程經濟系統和運籌學碩士，在管理、財務及投資銀行方面擁有逾25年經驗。</p>
								<p>冼先生是中手游（302.hk）的聯合創始人及副董事長。中手游於2009年成立，於2012年9月在美國上市，是中國首家在海外上市的手機遊戲公司。2019年10月回歸香港交易所主板上市，成為美股回歸港股的先鋒及深港合作和大灣區創業合作的重要示範。冼先生於2015年在深圳創立了一個風投基金國宏嘉信，成為內地知名基金，投資多個香港青年在內地創業的項目。</p>
								<p>冼先生積極參與香港創科及青少年有關的工作，現任選舉委員會科技創新界選舉委員、互聯網專業協會會長、香港軟件行業協會常務副會長、青年議會會長。冼先生獲特區政府委任為數碼港董事及曾擔任青年發展委員會委員。他同時擔任為天津市政協委員。另外是2018年青年工業家獎得主、2021年第三屆「香港創新領軍人物大獎」得主、2021年獲頒「領航粵港澳大灣區傑出企業家獎」等。冼先生於2021年獲香港特區政府頒授榮譽勳章。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_1_2.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>李鍵邦先生</h5>
								<p>鈦研科技有限公司聯合創辦人兼董事</p>
								<p>李鍵邦先生（Michael）畢業於墨爾本皇家理工大學電機工程系，他於2015年第66屆英特爾國際科學與工程大獎賽憑「自潔門柄」贏得材料科學組別二等獎，編號31905的小行星以他命名。</p>
								<p>在2017年，他加入了IVE太陽能車隊到澳洲參加世界太陽能車挑戰賽。同年亦創立鈦研科技有限公司把「自潔門柄」推出市場。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_1_3.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>陳易希先生，BBS</h5>
								<p>Bull.B Technology Limited 創辦人</p>
								<p>知名香港年輕科創發明家陳易希先生（Stark），於2004年，他以「人面識別家居智能保安機械人」獲獎，其後更獲得了美國麻省理工大學用他的名字為一顆小行星命名，以表揚他在科技比賽中表現出眾為港爭光。他在大學畢業後於數碼港成立科創公司 Bull.B Tech，並自設開發及科研團隊，團隊由30多位年輕、具創意的軟件工程師、科研人員等組成，著重創新創意，多年研發許多不同類型的新產品，旨在以科技為人類生活帶來更大方便，並推廣年輕的科研人員將天馬行空的意念實踐，研發出對社會及大眾有貢獻的發明。</p>
								<p>陳易希最近開發多項教育相關產品，如校家角、Classeek等。他亦開發了多項金融科技產品，包括同時具備通訊和加密貨幣錢包功能的「TALK+」、NFT交易平台「Only and 1」等。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_1_4.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>楊全盛先生，JP</h5>
								<p>香港電競總會創會會長</p>
								<p>楊全盛先生（Eric）為天旭科技投資集團聯合創辦人暨行政總裁，積極參與創新科技界的事務。他是香港電競總會創會會長、香港智慧城市聯盟創辦人及榮譽會長，以及香港都會大學李兆基商業管理學院榮譽副教授等。</p>
								<p>楊先生為現任香港數碼港管理有限公司董事和數字化經濟發展委員會成員。楊先生重視青少年全人發展，致力透過推廣創新科技向社會宣揚正面信息，並鼓勵青少年熱心貢獻社會。於2010年，楊先生獲海港青年商會選為第四屆青年領袖。</p>
							</div>
						</div>

					</div>
					<div className="PanelWrapper">
						<h1>金融新時代</h1>
						<h6>數碼科技創新金融服務</h6>

						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_2_1.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>主持人、嘉賓講者</p>
								<h5>周駱美琪女士</h5>
								<p>阿里巴巴香港創業者基金執行董事</p>
								<p>周駱美琪女士（Cindy）自2015年起擔任阿里巴巴香港創業者基金的執行董事。該基金的使命是幫助香港企業家和年輕人實踐創業夢想。為此，基金投資於初創公司，並組織各種活動以支持創新科技和創業生態。</p>
								<p>Cindy於2007年加入阿里巴巴集團，曾擔任阿里巴巴國際高級財務董事，主要負責集團財務管理和企業規劃。在加入阿里巴巴之前，Cindy曾在香港多間上市公司任職，負責融資、財務管理和規劃。</p>
								<p>Cindy擁有工商管理碩士學位，亦是一名註冊會計師。她是香港特別行政區政府創新、科技及再工業化委員會及語文教育及研究常務委員會成員、數碼港投資者網絡策劃小組主席、香港中文大學環球校友諮詢委員會、團結香港基金顧問等。Cindy希望透過阿里巴巴香港創業者基金發起的各種項目，協助創業家和年輕人實踐他們的夢想。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_2_2.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>李家達先生</h5>
								<p>WeLab Bank行政總裁兼執行董事</p>
								<p>李家達先生（Tat）是WeLab Bank行政總裁兼執行董事。本著透過金融科技幫助客戶輕鬆掌握理財路線的理念，李家達從2018年起便參與WeLab Bank的籌建。他帶領團隊奠立多個里程碑，包括取得虛擬銀行牌照、創立WeLab Bank的理念和定位以及帶領銀行拓展多元化業務等，令WeLab Bank成為廣受歡迎的虛擬銀行。</p>
								<p>李家達擁有20年金融科技及零售銀行經驗，他在2013年加入WeLab Bank母公司WeLab集團，先後出任多個重要職務，包括集團內的純網上貸款平台WeLend的總經理以及風險管理及產品部負責人。他更成功將WeLend的業務由一間初創公司發展成為全港最大、結合科技及人工智能的純網上貸款平台。此前，李家達曾在多間國際金融機構出任要職，包括滙豐銀行以及前渣打銀行消費信貸附屬機構安信信貸的管理職務。</p>
								<p>李家達持有香港科技大學工學士（計算機科學）學位。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_2_3.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>黃永東先生</h5>
								<p>Qupital聯合創始人兼首席執行官</p>
								<p>黃永東先生（Winston）是Qupital的聯合創始人兼首席執行官。Qupital是一家發展迅速的香港金融科技初創公司，專注於為廣大中小企提供貿易融資服務。Qupital運營著一個安全可靠的綫上平台，中小企透過平台即可輕易與專業投資者連結，快速籌集優質資金，達至雙贏效果。憑借大數據、機器學習和預測性分析等先進技術，Qupital能夠實現授信決策及實時監控的自動化。</p>
								<p>黃永東先生在大中華區的金融及科技領域擁有豐富的經驗，是國際保理聯合會（FCI）認可的保理專業人士，並具有貿易融資及電子商務融資的背景，曾在CSHF金融保理控股和三菱UFJ金融集團工作。黃永東先生擁有香港科技大學計算機工程學士學位，2020年入選福布斯30歲以下精英榜單，並於2022年獲選為粵港澳大灣區傑出青年企業家。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_2_4.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>顏耀輝先生</h5>
								<p>Bowtie聯合創辦人/聯合行政總裁/ 董事</p>
								<p>顏耀輝先生（Fred）是保泰人壽（Bowtie）的聯合創辦人及聯合行政總裁。</p>
								<p>Bowtie是一間立足香港、「以使命為本」的人壽及醫療保險公司。 Fred深信保險的初衷是保障生活所需，並認為人人都應獲得醫療保障。以填補香港的醫療保障缺口為使命，Fred聯合創立了香港首間虛擬保險公司Bowtie，以創新科技挑戰傳統保險公司模式，為大眾提供平等、簡單、可負擔的醫療保障。</p>
								<p>Fred熱衷於創新科技，在創立Bowtie之前，共同創立了Coherent Capital Advisors 及 Seasonalife。此前，他在芝加哥、倫敦、多倫多和香港多間國際知名顧問公司擔任顧問精算師超過10年。</p>
								<p>Fred現為香港保險業聯會客戶為本專責小組、香港管理專業協會保險管理委員會、保險業監督未來工作小組及香港金融發展局轄下市場推廣小組的成員。</p>
								<p>Fred亦是一名北美精算師協會（FSA）成員及亞洲金融科技師學會（FFT）榮譽會員。</p>
							</div>
						</div>

					</div>
					<div className="PanelWrapper">
						<h1>文體新視角</h1>
						<h6>推動文體產業化的多元及融合發展</h6>

						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_3_1.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>主持人、嘉賓講者</p>
								<h5>霍啟剛先生，JP</h5>
								<p>香港立法會議員（功能界別 - 體育、演藝、文化及出版界）</p>
								<p>霍啟剛先生（Kenneth）現任全國政協委員，全國青年聯合會副主席，中國文學藝術界聯合會全國委員會委員，香港特別行政區立法會議員（體育、演藝、文化及出版界功能界別），現職霍英東集團副總裁。 </p>
								<p>霍先生一直支持體育文化藝術發展，現任中國香港體育協會暨奧林匹克委員會副會長、香港藝術發展局成員、西九文化區管理局董事局成員及公私營合作項目委員會成員、中國文學藝術界聯合會香港會員總會常務副會長、香港出版總會名譽會長、香港演藝界內地發展協進會執行委員和香港電影製作發行協會顧問等。霍先生積極建立粵港澳文化藝術交流平台，於2021年策劃了「敢動藝術」項目，透過藝術家與運動員的互動，創造了跨界別合作的新範例。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_3_2.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>胡恩威先生</h5>
								<p>進念‧二十面體藝術暨行政總監、「香港一帶一路城市文化交流會議」召集人</p>
								<p>胡恩威先生（Mathias）是香港進念．二十面體藝術及行政總監。華人科技媒體藝術先鋒，修讀建築，從事劇本創作、劇場編導、多媒體及舞台設計、建築設計、藝術教育、文化政策評論等工作，是跨界劇場及多媒體劇場先鋒，作品曾應邀於二十多個中國內地及國際城市演出，作品包括《華嚴經》、《萬曆十五年》、《半生緣》、《建築城市》、《東宮西宮》系列等。</p>
								<p>2009年，他策劃了香港首個以建築為題的「建築是藝術節」，探索建築及劇場的各種藝術可能。同年，策劃「國民藝術教育計劃 ── 藝術及設計系列」，讓公眾認識中國的傳統美學觀念和現代意義。2017起策劃「Z Innovation Lab 進念舞台創新實驗室」，發掘表演藝術與舞台嶄新科技藝術的結合。</p>
								<p>2018年，胡恩威獲委任為中國人民政治協商會議江蘇省委員會委員。同時亦擔任全國港澳研究會會員、國家藝術基金評審專家及中國文藝評論家協會會員。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_3_3.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>姜偉靖先生</h5>
								<p>微辣文化有限公司執行董事</p>
								<p>姜偉靖先生（William）港澳長大，中學移居倫敦，後入讀倫敦大學國王學院。現任微辣文化執行董事，青年創意空間創會會長，汕頭市政協委員。為著名藝術影視音樂制作人，環保素食主義者。</p>
								<p>2003年起於亞洲和美國加州參與音樂制作工作。及後於2007年回流澳門發展音樂和藝術業務，團隊成功紮根後，跟澳門特區政府文產基金合作，成立「佰家文化」孵化中心推動文創產業發展。自2015年起孵化影視自媒體「微辣 Manner」，以創作幽默內容，迅速吸納大量粉絲關注，並屢獲殊榮。現於香港及廣州均設團隊，以創作影視娛樂IP，策劃澳門文化項目領赴海外，於各社交平臺吸納超過1500萬名微辣粉絲。</p>
								<p>姜偉靖近年積極關注環保減碳議題，透過其政團於立法會議席，參與環保政策及立法，支持澳門環保組織提升廢物分類回收質量，於微辣內推廣素食減碳排，減少一次性塑膠保護環境等生活意識，提倡生活綠色化。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_3_4.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>麥雅端女士</h5>
								<p>Chocolate Rain 創辦人及創作總監</p>
								<p>本地創作品牌 Chocolate Rain 由創作總監麥雅端女士（Prudence）於 2000 年創辦，Chocolate Rain 以想像力和初心，一點一滴創造一個又一個如夢境一樣美好的故事和展覽，鼓勵年輕人熱愛環境，釋放正能量和創造力，設計靈感源自孩童的夢想及奇妙的旅程，以獨一無二的設計和親手製作的手工為最大特色。Chocolate Rain 創作總監麥雅端，生於斯長於斯，其作品就是一個香港人的故事。Prudence 2008年獲香港青年設計才俊大獎獎學金到倫敦中央聖馬丁設計學院修讀碩士課程。2010年榮獲香港十大傑出設計師，並於2012年榮列香港十大傑出青年，其後獲得亞太經合會Young Women Innovator Award 2013。2017年得到 SIE Good Seed Award成立社創企劃香港的童話，2017 HK Licensing GOLD Award。</p>
								<p>Chocolate Rain 曾經在世界各地展出，包括古根漢博物館、大英博物館等，於歐洲各地推出其國際品牌授權設計。品牌亦常與國際品牌、慈善機構、環保組織等合作，以不同媒界宣揚保育關愛的訊息。</p>
							</div>
						</div>


						<div>
							<div className="Thumbnail">
								<div><img src={process.env.PUBLIC_URL + '/images/2022/speaker_3_5.jpg'} alt="" /></div>
							</div>
							<div className="Bio">
								<p>嘉賓講者</p>
								<h5>許煒森女士</h5>
								<p>前港隊三項鐵人精英運動員、兩屆奧運主持及評述員</p>
								<p>許煒森女士（Vincci）從精英運動員走到職場上當管理人員，她認為每個人，尤其是運動員，都有獨特的價值和生活經歷創造可持續的社會價值。Vincci曾經是一名香港三項鐵人運動員，最佳青年世界排名為第17名、亞洲第2名（2010年青年奧運會）。八年的學生運動員經歷，成功培育出她那運動員獨有的心態和質素，如自律、堅強、和不怕輸的精神。</p>
								<p>Vincci 的經驗包括活動管理、數碼營銷、公共關係、商業項目營運、人才招募、公司品牌推廣、社會創新及兒童發展籌款等。過去十多年來，她曾擔任電視體育節目主持人、兩屆奧運評述員、報章體育版專欄作者，亦在體育慈善機構中擔任義務工作多年，發揮她對本地體育界的熱情和肯拼的精神。她同時是一位勵志演講者，在 TEDx 和不同學校分享逆境管理和個人身心的發展。</p>
								<p>Vincci 現時是本地最大非醫院醫療美容服務供應商的商業項目經理，將運動健康心境帶進工作環境，發展和提升本地一站式多元化多維度的醫學醫療、美容養生及時尚生活的服務質素。她同時是一位多媒體內容創作者，經常分享運動及生活點滴 。Vincci 亦是一位執業調解員。她在香港城市大學獲政策及行政學士學位（一等榮譽）。</p>
							</div>
						</div>

					</div>
				</div>
			</section>
		</div>
	);
}

export default MiniSite2022Panel;
