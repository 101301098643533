
import React from "react";
import Contact from "./Contact";

function EndSubmissionMessage() {
	return (
		<section id="end_submit">
			<a className="btn backto2022" target="_blank" href="/2022/"> 2022 論壇回顧 </a>
			<h2>活動登記已經結束。</h2>
			<h3>如有任何查詢，請電郵至<Contact /></h3>
			<h2>感謝您的參與！</h2>
		</section>
	)
}

export default EndSubmissionMessage;