import React from "react";
import Contact from "./Contact";

function AppForm2023AfterSubmitFormMessage() {
	/*const location = useLocation();
  	const [previousPath, setPreviousPath] = useState('');

	useEffect(() => {
		if (location.state && location.state.previousPath) {
			setPreviousPath(location.state.previousPath);
		}
	}, [location.state]);

	const history = useHistory();

	console.log(previousPath)
	console.log(history)
	return (previousPath !== '') ?
	(
		<section id="after_submit">
			<h2>您已經完成登記，成功報名的參加者將會在論壇前約一星期收到電郵通知。</h2>
			<h3>如有任何查詢，請電郵至<Contact /></h3>
			<h2>感謝您的參與！</h2>
			<a className="btn" href="/2023/form">返回網站 </a>
		</section>
	) : window.location.href='/2023/form'
	*/
	return(
		<section id="after_submit">
			<h2>您已經完成登記，成功報名的參加者將會在論壇前約一星期收到電郵通知。</h2>
			<h3>如有任何查詢，請電郵至<Contact /></h3>
			<h2>感謝您的參與！</h2>
			<a className="btn" href="/2023/form">返回網站 </a>
		</section>
	)
}

export default AppForm2023AfterSubmitFormMessage;