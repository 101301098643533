
import React from "react";

function Contact() {
	return (
		<span>
			{" "}<a href="mailto:enquiry@gbayouthforum.org.hk">enquiry@gbayouthforum.org.hk</a>{" "}或{" "}WhatsApp<a href="https://wa.me/+85293824596" target="_blank">（+852）9382 4596</a>
		</span>
	)
}

export default Contact;